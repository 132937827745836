<template>
    <div>
        <div class="crumbs">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>
                    <i class="el-icon-lx-cascades"></i> 帳號列表
                </el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <el-row class="add-btn">
            <el-col>
                <el-button type="primary"
                           @click="toPage('createAccount')">新增帳號<i class="el-icon-lx-add el-icon--right"></i></el-button>
            </el-col>
        </el-row>
        <div class="container">
            <el-table :data="tableData"
                      border
                      class="table">
                <el-table-column prop="title"
                                 width="180"
                                 label="角色"
                                 align="center" />
                <el-table-column prop="account"
                                 label="帳號"
                                 align="center" />
                <el-table-column prop="name"
                                 label="名稱"
                                 align="center" />
                <el-table-column prop="created_at"
                                 width="180"
                                 label="建立時間"
                                 align="center" />
                <el-table-column prop="depiction"
                                 label="敘述"
                                 align="center" />
                <el-table-column label="操作"
                                 align="center"
                                 width="180">
                    <template #default="scope">
                        <el-button size="mini"
                                   icon="el-icon-edit"
                                   @click="handleEdit('editAccount', scope.row)">編輯</el-button>
                        <el-button size="mini"
                                   icon="el-icon-delete"
                                   class="red"
                                   @click="handleDelete(scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分頁 -->
            <div class="pagination">
                <el-pagination background
                               layout=" prev, pager, next"
                               :current-page="pageIndex"
                               :page-size="pageSize"
                               :page-count="pageCount"
                               @current-change="handlePageChange">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import { adminUsersList, adminUsersDelete } from '../../../api/index'
export default {
    data() {
        return {
            tableData: [],
            pageIndex: 1,
            pageSize: 10,
            pageCount: 0,
        }
    },
    mounted() {
        this.getData()
    },
    methods: {
        getData() {
            let data = {
                page: this.pageIndex,
            }
            adminUsersList(data).then((res) => {
                if (res.code == 300) {
                    this.tableData = res.data.data_list
                    this.pageCount = res.data.total_page
                } else if (res.code == 500) {
                    this.$message.error(res.message)
                }
            })
        },
        toPage(page) {
            this.$router.push({ path: page })
        },
        //編輯
        handleEdit(page, info) {
            this.$router.push({
                path: page,
                query: { id: info.id },
            })
        },
        //刪除
        handleDelete(row) {
            this.$confirm('确定要删除吗？', '提示', {
                type: 'warning',
            })
                .then(() => {
                    adminUsersDelete({ id: row.id }).then((res) => {
                        if (res.code == 200) {
                            this.$message.success('删除成功')
                            this.getData()
                        }
                    })
                })
                .catch(() => {})
        },
        // 分页导航
        handlePageChange(val) {
            this.pageIndex = val
            this.getData()
        },
    },
}
</script>

<style lang="scss" scoped>
.add-btn {
    padding-bottom: 15px;
}
.red {
    color: #ff0000;
}
</style>